<template lang="pug">
span.d-flex.flex-row
  v-menu(:disabled="disabled")
    template(v-slot:activator="{ on }")
      span.photos-btn(v-on="on")
        v-icon mdi-download
        v-icon.copy-arrow(small) mdi-menu-down
    v-list.user-menu
      course-photo(:courseID="courseID", :coursePhoto="coursePhoto")
        template(v-slot="{ loading, download }")
          v-list-item.user-menu__item(@click="download", :disabled="!$route.query.course_day")
            v-list-item-title Download course photo
      candidates-photo(:courseID="courseID", :activeItems="activeItems")
        template(v-slot="{ loading, download }")
          v-list-item.user-menu__item(@click="download", :disabled="!hasActiveItems")
            v-list-item-title Download student photo
      candidates-photo(
        :courseID="courseID",
        :activeItems="activeItems",
        consentOnly
      )
        template(v-slot="{ loading, download }")
          v-list-item.user-menu__item(@click="download", :disabled="!hasActiveItems")
            v-list-item-title Download photo with consent only
      //- v-list-item(@click="copyPhone")
      //-   v-list-item-title Copy telephone number
</template>

<script>
import CoursePhoto from "../../../course_day/components/actions/CoursePhotoDownload.vue";
export default {
  name: "CandidatesPhoto",

  props: {
    activeItems: Array,
    courseID: Number,
    coursePhoto: String,
    disabled: Boolean,
    hasActiveItems: Boolean,
    list: Array,
  },

  methods: {},

  components: {
    CoursePhoto,
    candidatesPhoto: () =>
      import(
        "@/app/admin/modules/course_day/components/actions/CandidatesPhoto.vue"
      ),
  },
};
</script>

<style lang="scss" scoped>
.copy-arrow::v-deep {
  &.disabled {
    opacity: 0.5;
    color: inherit;
    cursor: not-allowed;
  }
}

.photos-btn {
  cursor: pointer;
  &:hover > .v-icon::v-deep {
    color: $primary-color;
  }
  &.disabled {
    opacity: 0.5;
    color: inherit !important;
    cursor: not-allowed;
  }
}
</style>
