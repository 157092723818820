var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"d-flex flex-row"},[_c('v-menu',{attrs:{"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"photos-btn"},on),[_c('v-icon',[_vm._v("mdi-download")]),_c('v-icon',{staticClass:"copy-arrow",attrs:{"small":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{staticClass:"user-menu"},[_c('course-photo',{attrs:{"courseID":_vm.courseID,"coursePhoto":_vm.coursePhoto},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var download = ref.download;
return [_c('v-list-item',{staticClass:"user-menu__item",attrs:{"disabled":!_vm.$route.query.course_day},on:{"click":download}},[_c('v-list-item-title',[_vm._v("Download course photo")])],1)]}}])}),_c('candidates-photo',{attrs:{"courseID":_vm.courseID,"activeItems":_vm.activeItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var download = ref.download;
return [_c('v-list-item',{staticClass:"user-menu__item",attrs:{"disabled":!_vm.hasActiveItems},on:{"click":download}},[_c('v-list-item-title',[_vm._v("Download student photo")])],1)]}}])}),_c('candidates-photo',{attrs:{"courseID":_vm.courseID,"activeItems":_vm.activeItems,"consentOnly":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var download = ref.download;
return [_c('v-list-item',{staticClass:"user-menu__item",attrs:{"disabled":!_vm.hasActiveItems},on:{"click":download}},[_c('v-list-item-title',[_vm._v("Download photo with consent only")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }